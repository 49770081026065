import * as Sentry from '@sentry/vue'
import { DateTime } from 'luxon'
import type { AppConfig } from 'vue'

interface ContextBase {
    contextType: string
}

export interface RequestContext extends ContextBase {
    contextType: 'request'
    requestInit: RequestInit
    url: string
}

export function createRequestContextFromRequest(url: string, requestInit: RequestInit): RequestContext {
    const req = new Request(url, requestInit)
    return {
        contextType: 'request',
        url,
        requestInit: {
            ...requestInit,
            method: req.method,
            referrer: req.referrer,
            referrerPolicy: req.referrerPolicy,
            mode: req.mode,
            credentials: req.credentials,
            cache: req.cache,
            redirect: req.redirect,
            integrity: req.integrity,
            keepalive: req.keepalive,
        },
    }
}

export interface ResponseContext extends ContextBase {
    contextType: 'response'
    ok: boolean
    status: number
    statusText: string
    url: string
    headers: object
    type: ResponseType
    bodyUsed: boolean
    redirected: boolean
}

export function createResponseContextFromResponse(response: Response): ResponseContext {
    return {
        contextType: 'response',
        ok: response.ok,
        status: response.status,
        statusText: response.statusText,
        url: response.url,
        headers: Array.from(response.headers.entries()),
        type: response.type,
        bodyUsed: response.bodyUsed,
        redirected: response.redirected,
    }
}

export interface ErrorContext extends ContextBase {
    contextType: 'error',
    message: string,
    name: string | undefined,
    stack: string | undefined,
    columnNumber: number | undefined,
    lineNumber: number | undefined,
}

function createErrorContextFromError(error: Error): ErrorContext {
    return {
        contextType: 'error',
        message: error?.message,
        name: error?.name,
        stack: error?.stack,
        //@ts-ignore
        columnNumber: error?.columnNumber,
        //@ts-ignore
        lineNumber: error?.lineNumber,
    }
}

export interface AppInfoContext extends ContextBase {
    contextType: 'appInfo'
    version: string
    backend: string
}

export interface BrowserContext extends ContextBase {
    contextType: 'browser'
    name: string,
    version: string | number,
    os: string | undefined,
    userAgent: string,
}

export interface UnstructuredContext extends ContextBase {
    contextType: 'unstructured'
    value: any
}

export interface UserContext extends ContextBase {
    contextType: 'user'
    user: string
}

export class MessageContext implements ContextBase {
    contextType: 'message'
    message: string

    constructor(message: string) {
        this.contextType = 'message'
        this.message = message
    }
}

export interface VueErrorContext extends ContextBase {
    contextType: 'vueError'
    /** vue error codes: https://vuejs.org/error-reference/#runtime-errors */
    info: string
}

export type Context = RequestContext |
    ResponseContext |
    ErrorContext |
    AppInfoContext |
    BrowserContext |
    UnstructuredContext |
    MessageContext |
    UserContext |
    VueErrorContext

export class LoggableError extends Error {
    context: Context[]

    constructor(context: Context | Context[], previousError: Error | unknown = null) {
        super()
        this.name = 'LogError'
        if (Array.isArray(context)) {
            this.context = context
        } else {
            this.context = [context]
        }

        const messageCtx = this.context.find((ctx) => ctx.contextType = 'message')
        if ((messageCtx as MessageContext)?.message != undefined) {
            this.message = (messageCtx as MessageContext).message
        }

        if (previousError instanceof Error) {
            this.cause = previousError
        }
    }

    public getContext(): Context[] {
        const contextList: Context[] = this.context

        let previous: null | Error | LoggableError = this?.cause as any ?? null
        while (previous != null) {
            if (previous instanceof LoggableError) {
                contextList.concat(previous.context)
            } else if (previous instanceof Error) {
                contextList.push(createErrorContextFromError(previous))
            }
            previous = previous?.cause as any ?? null
        }

        return contextList.reverse()
    }
}

type LogSubscriber = (newEntries: LogEntry[]) => void
export const LogLocalStorageKey = 'log'
export const LOCAL_STORAGE_MAX_ENTRIES = 200
/** If log is larger than MAX_ENTRIES, only the last 150 entries are kept. */
export const LOCAL_STORAGE_SOFT_MAX_ENTRIES = 150
export const LOCAL_STORAGE_MAX_AGE = 31 * 24 * 60 * 60 * 1000 // 31 days
export enum SEVERITY {
    'INFO',
    'WARNING',
    'ERROR',
}

export interface LogEntry {
    timestamp: number // unix time
    severity: SEVERITY
    context: Context[]
}

export class Log {
    subscribers: Set<LogSubscriber> = new Set()
    /** sorted timestamp: old -> new */
    private entries: LogEntry[] = []

    constructor(entries: LogEntry[] = []) {
        entries.sort((a, b) => a.timestamp - b.timestamp)
        this.setEntries(entries)
    }

    setEntries(entries: LogEntry[]): void {
        entries.sort((a, b) => a.timestamp - b.timestamp)
        this.entries = entries
    }

    addEntry(logEntry: LogEntry): void {
        if (this.entries.length === 0) {
            this.entries.push(logEntry)
        } else if (this.entries.length === 1) {
            if (this.entries[0].timestamp > logEntry.timestamp) {
                this.entries.unshift(logEntry)
            } else {
                this.entries.push(logEntry)
            }
        } else if (this.entries.at(-1)!.timestamp < logEntry.timestamp) {
            // is newest
            this.entries.push(logEntry)
        } else if (this.entries.at(0)!.timestamp > logEntry.timestamp) {
            // is oldest
            this.entries.unshift(logEntry)
        } else {
            // is in the middle
            const index = this.entries.findLastIndex(x => x.timestamp < logEntry.timestamp)
            if (index !== -1) {
                this.entries.splice(index, 0, logEntry)
            } else {
                this.entries.push(logEntry)
            }
        }
        this.notifySubscribers([logEntry])
        this.cleanup()
    }

    getEntries(): LogEntry[] {
        return this.entries
    }

    subscribe(subscriber: LogSubscriber): void {
        this.subscribers.add(subscriber)
    }

    unsubscribe(subscriber: LogSubscriber): void {
        this.subscribers.delete(subscriber)
    }

    error(error: unknown): void {
        if (error instanceof LoggableError) {
            this.fromLoggableError(error)
        } else if (error instanceof Error) {
            this.fromError(error)
        }
    }

    private fromLoggableError(error: LoggableError): void {
        this.wrapContext(error.getContext())
    }

    private fromError(error: Error): void {
        this.wrapContext([createErrorContextFromError(error)])
    }

    private notifySubscribers(newEntries: LogEntry[]): void {
        this.subscribers.forEach(subscriber => subscriber(newEntries))
    }

    private wrapContext(context: Context[]): void {
        const logEntry: LogEntry = {
            timestamp: DateTime.now().toMillis(),
            severity: SEVERITY.ERROR,
            context,
        }
        this.addEntry(logEntry)
    }

    private cleanup(): void {
        if (this.entries.length === 0) return
        const beforeCount = this.entries.length

        if (this.entries.length > LOCAL_STORAGE_MAX_ENTRIES) {
            this.entries.splice(0, this.entries.length - Math.min(LOCAL_STORAGE_SOFT_MAX_ENTRIES, LOCAL_STORAGE_MAX_ENTRIES))
        }

        const now = DateTime.now().toMillis()
        if (this.entries[0].timestamp < now - LOCAL_STORAGE_MAX_AGE) {
            const oldestAllowedIndex = this.entries.findIndex(x => x.timestamp < now - LOCAL_STORAGE_MAX_AGE)
            if (oldestAllowedIndex !== -1) {
                this.entries.splice(oldestAllowedIndex, this.entries.length - oldestAllowedIndex)
            } else {
                this.entries.splice(0, this.entries.length)
            }
        }

        const afterCount = this.entries.length

        if (afterCount < beforeCount) {
            log.addEntry({
                timestamp: DateTime.now().toMillis(),
                severity: SEVERITY.INFO,
                context: [
                    {
                        contextType: 'message',
                        message: `Log entries removed: ${beforeCount - afterCount}`,
                    },
                ],
            })
        }
    }
}

export const localStorageLogSubscriber: LogSubscriber = () => {
    localStorage.setItem(LogLocalStorageKey, JSON.stringify(log.getEntries()))
}

export const consoleLogSubscriber: LogSubscriber = (entries) => {
    entries.forEach(entry => {
        console.log(entry.context)
    })
}

// APP Log
export const log = new Log()
log.subscribe(localStorageLogSubscriber)

try {
    const localEntries = JSON.parse(localStorage.getItem('log') ?? '[]')
    if (Array.isArray(localEntries)) {
        log.setEntries(localEntries)
    }
} catch {
    // ignore
}

export const logVueErrorHandler: AppConfig['errorHandler'] = (err, instance, info) => {
    Sentry.captureException(err, {})
    let errContext: Context[] = []
    if (err instanceof LoggableError) {
        errContext = err.getContext()
    } else if (err instanceof Error) {
        errContext = [createErrorContextFromError(err)]
    }

    log.addEntry({
        timestamp: DateTime.now().toMillis(),
        severity: SEVERITY.ERROR,
        context: [
            {
                contextType: 'vueError',
                info,
            },
            ...errContext,
        ],
    })
}



