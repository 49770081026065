<script lang="ts" setup>
import { useAuthStore } from "@/store/AuthStore.ts"
import { type User, useUserInfoStore } from "@/store/userInfo.ts"
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue"
import { computed, ref, watch } from "vue"

const authStore = useAuthStore()

const props = defineProps<{
    popupPosition: "left" | "right"
}>()

const userStore = useUserInfoStore()
const currentUser = ref<User | null>(null)

watch([() => authStore.currentUser?.userId,
    () => userStore.users], async ([id]) => {
    if (id != null && !Number.isNaN(id)) {
        currentUser.value = await userStore.get(id) ?? null
    }
}, {immediate: true})

const intials = computed(() => {
    const name = currentUser.value?.caption !== '' ? currentUser.value?.caption : currentUser.value?.name
    if (name) {
        return ((name.charAt(0)?.toUpperCase() ?? '') + (name.charAt(1) ?? ''))
    } else {
        return null
    }
})

const hasImage = computed(() => currentUser.value?.image.small != null)

function animationFriendlyLogout() {
    setTimeout(() => authStore.logout(), 50)
}
</script>

<template>
    <Menu
        as="div"
        class="relative"
    >
        <MenuButton
            class="relative size-10 rounded-full bg-gray-600 font-bold text-gray-100 ui-open:outline ui-open:outline-cs-300"
        >
            <Transition
                appear
                enter-active-class="transition-all duration-200"
                enter-from-class="blur-sm"
                enter-to-class="blur-none"
                mode="out-in"
                name="fade"
            >
                <img
                    v-if="hasImage"
                    :src="`data:image/jpeg;base64,${currentUser?.image.small}`"
                    alt="your profile picture"
                    class="absolute left-0 top-0 size-10 rounded-full bg-white shadow-lg"
                >
                <div
                    v-else-if="intials == null"
                    class="absolute left-0 top-0 grid size-10 place-items-center rounded-full bg-gray-600 p-3 shadow-lg"
                >
                    <IconFA
                        icon="user"
                        class="size-full text-gray-200"
                    />
                </div>
                <div
                    v-else
                    class="absolute left-0 top-0 grid size-10 place-items-center rounded-full bg-gray-600 text-white shadow-lg"
                >
                    <span>
                        {{ intials }}
                    </span>
                </div>
            </Transition>
        </MenuButton>
        <Transition
            appear
            name="slide-down-up"
        >
            <MenuItems
                :class="{'left-0': props.popupPosition === 'left',
                         'right-0': props.popupPosition === 'right',}"
                class="absolute z-10 mt-1 flex flex-col rounded-md
             border bg-white text-gray-600 shadow-lg focus:outline-none dark:border-cs-950
             dark:bg-cs-900 dark:text-gray-300"
            >
                <MenuItem
                    v-slot="{active}"
                >
                    <div
                        :class="{'bg-cs-100 dark:bg-cs-600 dark:text-white':active}"
                        class="flex cursor-pointer items-baseline
                    justify-between gap-2 rounded-md p-2
                    "
                        @click="$router.push(authStore.currentUser?.userId != null ? `/administration/benutzer/${authStore.currentUser.userId}/profil` : '/')"
                    >
                        <span>Profil</span>
                        <IconFA
                            class="aspect-square text-sm"
                            icon="user"
                        />
                    </div>
                </MenuItem>
                <MenuItem
                    v-slot="{close}"
                >
                    <div
                        class="flex cursor-pointer items-baseline
                    justify-between gap-2 rounded-md p-2
                    ui-active:bg-cs-100 ui-active:dark:bg-cs-600 ui-active:dark:text-white"
                        @click="() =>{close();animationFriendlyLogout()}"
                    >
                        <span>Abmelden</span>
                        <IconFA
                            class="aspect-square text-sm"
                            icon="arrow-right-from-bracket"
                        />
                    </div>
                </MenuItem>
            </MenuItems>
        </Transition>
    </Menu>
</template>
