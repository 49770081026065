const colorMap = {
    'api.dev.curatest': '#00a4a7',
    'api.release.curatest': '#9b0056',
    'api.stage.curatest': '#6950a1',
    'api.curatest': '#ee7c0e',
    'localhost': '#84b817',
}

export default function urlToColor(url: string) {
    for (const [path, color] of Object.entries(colorMap)) {
        if (url.includes(path)) return color
    }
    return undefined
}