import { notifyErrorNotAuthRelated } from '@/api/utils'
import { clearCustomerPageSessionCache } from '@/models/Types'
import { useAuthStore } from '@/store/AuthStore.ts'
import qs from 'querystringify'
import { createRouter, createWebHistory, type RouteLocationNormalized } from 'vue-router'
import routes from '~pages'

const router = createRouter({
    history: createWebHistory(),
    routes,
})

function guardClearCustomerPageSessionCache(to: RouteLocationNormalized) {
    if (!to.path.includes("/kunden")) {
        clearCustomerPageSessionCache()
    }
}

function guardOverwriteLoginTokenFromURL(to: RouteLocationNormalized): void {
    const token = to.query?.token ? String(to.query.token) : null
    if (token != null) {
        delete to.query.token
        to.fullPath = removeParameterFromURL(to.fullPath, 'token')
        //@ts-ignore href is there but not in type definition
        if (to.href != undefined) to.href = removeParameterFromURL(to.href, 'token')

        useAuthStore().setToken(token)
    }
}

function removeParameterFromURL(url: string, parameter: string): string {
    const splitPath = url.split('?')
    if (splitPath.length === 1) return url

    const params = qs.parse(splitPath[1])
    delete params[parameter]
    url = splitPath[0] + qs.stringify(params, true)
    return url
}

async function guardAuthenticated(to: RouteLocationNormalized) {
    const authStore = useAuthStore()
    /** Pages Config and Login don't require to be logged in. */
    if (authStore.isLoggedOut && !to.meta.withoutAuth) {
        await authStore.updateSession()
            .then((isLoggedIn) => {
                if (!isLoggedIn) {
                    router.replace("/login" + qs.stringify({next: to.path}, {addQueryPrefix: true}))
                }
            }).catch(notifyErrorNotAuthRelated)
    }
}

router.beforeEach(async (to) => {
    guardClearCustomerPageSessionCache(to)
    guardOverwriteLoginTokenFromURL(to)
    await guardAuthenticated(to)
})

export default router
